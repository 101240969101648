import React from "react";
import Layout from "../components/Layout";
// images
import chevron from "../images/references/chevron.png";
import omv from "../images/references/omv.png";
import wintershall from "../images/references/wintershall.png";
import fetsa from "../images/references/fetsa.jpg";
import innov2020 from "../images/references/innov2020.jpg";
import tankstorage from "../images/references/tankstorage.jpg";
import tuvsud from "../images/references/tuvsud.png";
import chevronBig from "../images/references/chevron_big.jpg";
import omvBig from "../images/references/omv_big.png";
import wintershallBig from "../images/references/wintershall_big.png";
// styles
import "../styles/global.css";
import "../styles/pageHeader.css";
import "../styles/references.css";

const data = [
  {
    logo: chevron,
    text:
      "The second-generation Pi Foam System, currently installed at Chevron’s Kuwait facility, will be capable of extinguishing a fire on a surface area the size of a 122m/400ft-diameter tank, making it the largest-capacity fixed firefighting system in the world.",
  },
  {
    logo: omv,
    text:
      "Built a fast-reacting system to provide instant-foam fire protection for their Early Production Facility in Yemen. 4 process tanks and 4 storage tanks protected.",
  },
  {
    logo: wintershall,
    text:
      "3 pcs. of 63,000 bbl floating roof tank protected. Refurbishment of the old, “traditional” extinguishment system.",
  },
  {
    logo: fetsa,
    text:
      "Swiss Fire Protection Research and Development AG is an official Supplier Partner of FETSA, the Federation of European Tank Storage Associations. FETSA Supplier Partners are seen as the leading companies in their field and help FETSA to achieve its mission of ensuring the responsibility, prosperity, safety and sustainable development of the bulk liquid storage sector.",
  },
  {
    logo: innov2020,
    text:
      "The „Pi Foam System” has been shortlisted for the 2020 Global Tank Storage Awards in the “Most Innovative Technology category”.",
  },
  {
    logo: tuvsud,
    text:
      "The TÜV examined and tested the first-generation Pi Foam System against EU fire-safety standards.",
  },
];

function references() {
  return (
    <Layout>
      <main className="page__container">
        <h1 className="page__title">References</h1>
        <div className="references">
          <div className="references__wrapper">
            {data.map((d, i) => {
              return (
                <div key={i} className="reference">
                  <div className="outer__wrapper">
                    <div className="references__img">
                      <img src={d.logo} alt="comapny logo" />
                    </div>
                    <div className="references__text">{d.text}</div>
                  </div>
                </div>
              );
            })}
            <div className="reference">
              <div className="outer__wrapper">
                <div className="references__img">
                  <img src={tankstorage} alt="comapny logo" />
                </div>
                <div className="references__text">
                  <p>
                    The „Pi Foam System” has been shortlisted for the 2019
                    Global Tank Storage Awards in 3 categories:
                  </p>
                  <ul style={{ listStyle: "initial" }}>
                    <li>Outstanding Terminal Safety Technology Award</li>
                    <li>Most Innovative Technology</li>
                    <li>Excellence in Environmental Protection Technology.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1 className="references__h2">Pi Foam System protected facilities</h1>
        <div className="ref__facilities">
          <div className="ref__facility">
            <h1>
              The second-generation Pi Foam System, installed at Chevron’s
              Kuwait facility
            </h1>
            <div>
              <img src={chevronBig} alt="chevron facility" />
            </div>
          </div>
          <div className="ref__facility">
            <h1>First-generation Pi Foam System protected facility in Yemen</h1>
            <div>
              <img src={omvBig} alt="omv facility" />
            </div>
          </div>
          <div className="ref__facility">
            <h1>
              Tanks to be protected by the first-generation Pi Foam System in
              Lybia
            </h1>
            <div>
              <img src={wintershallBig} alt="wintershall facility" />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default references;
